<script>
import STable from '@/components/Table'
import moment from 'moment'
import CloudServiceNodeInfo from '@/views/cert/modal/CloudServiceNodeInfo.vue'
import { loadCloudSettingInfoList } from '@/api'

export default {
  name: 'Domain',
  components: {
    STable
  },
  data () {
    return {
      loadData: (paramter) => {
        return loadCloudSettingInfoList(paramter).then(res => {
          return res.result
        })
      },
      columns: [{
        title: this.$t('cloud.config.name'),
        dataIndex: 'name',
        align: 'center'
      }, {
        title: this.$t('cloud.provider'),
        dataIndex: 'service_type',
        scopedSlots: { customRender: 'cloud_type' },
        align: 'center'
      }, {
        title: this.$t('cloud.service.product'),
        dataIndex: 'service_type',
        scopedSlots: { customRender: 'service_type' },
        align: 'center'
      }, {
        title: this.$t('cloud.domain'),
        dataIndex: 'domains',
        scopedSlots: { customRender: 'domains' },
        align: 'center'
      }, {
        title: this.$t('cloud.add.time'),
        dataIndex: 'create_time',
        scopedSlots: { customRender: 'time' },
        align: 'center'
      }, {
        title: this.$t('cloud.remark'),
        dataIndex: 'remark',
        align: 'center'
      }, {
        title: this.$t('user.action'),
        dataIndex: 'action',
        width: '150px',
        scopedSlots: { customRender: 'action' },
        align: 'center'
      }
      ]
    }
  },
  methods: {
    moment,
    showEditNode (record) {
      this.showModal(CloudServiceNodeInfo, this.$t('user.cert.editNode'), 900, 600, { record: record })
    },
    refreshTable () {
      this.$refs.table.refresh()
    }
  }
}
</script>

<template>
  <div>
    <div>
      <a-input-search style="width: 280px;"></a-input-search>
      <a-button icon="plus" class="margin-left-16" @click="showEditNode(null)">{{ $t('cloud.service.product.config') }}</a-button>
    </div>
    <div class="ant-card" style="margin-top: 8px;">
      <s-table ref="table" :columns="columns" :data="loadData" bordered size="small">
        <div slot="cloud_type" slot-scope="text,record">
          <span v-if="record.cloud_type==1">{{ $t('cloud.provider.aliyun') }}</span>
          <span v-if="record.cloud_type==2">{{ $t('cloud.provider.tencentcloud') }}</span>
          <span v-if="record.cloud_type==3">七牛云</span>
          <span v-if="record.cloud_type==9">{{ $t('cloud.provider.aws') }}</span>
        </div>
        <div slot="service_type" slot-scope="text,record">
          <span v-if="record.service_type==2">OSS</span>
          <span v-if="record.service_type==1">CDN</span>
          <span v-if="record.service_type==3">DCDN</span>
          <span v-if="record.service_type==4">SCDN</span>
          <span v-if="record.service_type==17">{{ $t('cloud.service.general') }}</span>
          <span v-if="record.service_type==18">{{ $t('cloud.service.general') }}</span>
        </div>
        <div slot="domains" slot-scope="text,record">
          <div v-for="domain in record.domains" :key="domain.domain">{{ domain.domain }}</div>
        </div>
        <div slot="time" slot-scope="text,record">
          {{ moment(record.create_time).format("YYYY-MM-DD HH:mm:ss") }}
        </div>
        <div slot="action" slot-scope="text,record">
          <a style="color: red;">{{ $t('user.action.delete') }}</a>
          <a-divider type="vertical"></a-divider>
          <a @click="showEditNode(record)">{{ $t('user.action.edit') }}</a>
        </div>
      </s-table>
    </div>
  </div>
</template>

<style scoped>

</style>
