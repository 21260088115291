<script>
import cloud from '@assets/cloud.json'
import { checkDomainDNS, loadCloudDomainInfoList, saveCloudAPIAuthInfo } from '@/api/index'
import { getI18nLanguage } from '@/locales'
const SMKEY = '10h7gWs1PbbTvzarF2s1zTuTqx8lqsYm'
const sm4 = require('sm-crypto').sm4

export default {
  name: 'CloudServiceNodeInfo',
  props: ['layerid', 'record'],
  data () {
    return {
      cStep: 0,
      cCloudType: '1',
      cServiceType: 0,
      cSettings: {},
      form: this.$form.createForm(this),
      cloud: cloud['zh-CN'],
      services: [],
      fields: [],
      columns: [{
        title: this.$t('domain.dns.domain'),
        dataIndex: 'domain',
        key: 'domain',
        align: 'center'
      }, {
        title: this.$t('cloud.cert.auto.renew.enable'),
        dataIndex: 'status',
        key: 'enable',
        align: 'center',
        scopedSlots: { customRender: 'enable' }
      }],
      loading: false,
      domains: [],
      enableDomainNum: 0,
      columns2: [{
        title: this.$t('domain.dns.domain'),
        dataIndex: 'host',
        key: 'host',
        align: 'center'
      }, {
        title: this.$t('domain.dns.host.record'),
        dataIndex: 'host_record',
        key: 'host_record',
        align: 'center'
      }, {
        title: this.$t('domain.dns.record.type'),
        dataIndex: 'record_type',
        key: 'record_type',
        align: 'center'
      }, {
        title: this.$t('domain.dns.record.value'),
        dataIndex: 'record_value',
        key: 'record_value',
        align: 'center'
      }, {
        title: '校验状态',
        dataIndex: 'status',
        scopedSlots: { customRender: 'status' },
        key: 'status',
        align: 'center'
      }, {
        title: '操作',
        dataIndex: 'action',
        key: 'action',
        scopedSlots: { customRender: 'action' },
        align: 'center'
      }],
      dnsErr: false,
      domainDns: [],
      saving: false
    }
  },
  created () {
    this.initParams(0)
  },
  methods: {
    initParams (index) {
      const _this = this
      if (this.record) {
        if (this.record.lang !== 'zh-CN') {
          this.cloud = cloud['en-US']
        }
      } else if (getI18nLanguage() !== 'zh-CN') {
        this.cloud = cloud['en-US']
        this.cCloudType = '9'
      }
      const clouds = this.cloud.clouds
      let filedSettings = null
      if (this.record) {
        filedSettings = JSON.parse(sm4.decrypt(this.record.setting_field, SMKEY))
        const cloudType = this.record.cloud_type + ''
        _this.cCloudType = cloudType
        for (let i = 0; i < clouds.length; i++) {
          if (clouds[i].id === cloudType) {
            index = i
            break
          }
        }
      }
      const cCloud = clouds[index]

      this.fields = cCloud.setting_fields
      this.services = cCloud.services
      let serviceIndex = 0
      if (this.record) {
        for (let i = 0; i < cCloud.services.length; i++) {
          const service = cCloud.services[i]
          if (service.service_type === this.record.service_type) {
            serviceIndex = i
          }
        }
      }

      this.cServiceType = _this.services[serviceIndex].service_type

      const obj = {
        cloud_type: _this.cCloudType,
        service_type: _this.cServiceType
      }
      if (filedSettings) {
        Object.assign(obj, filedSettings)
      }
      if (this.record) {
        obj.name = this.record.name
      }
      setTimeout(function () {
        _this.form.setFieldsValue(obj)
      }, 1000)
    },
    handleSubmit (e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          this.cServiceType = values.service_type
          const cSettings = JSON.parse(JSON.stringify(values))
          delete cSettings.cloud_type
          delete cSettings.cloud_type
          this.cSettings = cSettings
          this.loading = true
          loadCloudDomainInfoList(values).then(res => {
            this.loading = false
            if (res.code === 0) {
              if (res.data.length === 0) {
                this.$message.error(this.$t('domain.dns.domain.not.found'))
                return
              }
              this.domains = res.data
              this.enableDomainNum = this.domains.length
              this.cStep = 1
            } else {
              this.$message.error(res.msg)
            }
          }).catch((err) => {
            this.loading = false
            this.$message.error(err.message)
          })
        }
      })
    },
    handleChangeCloudType (e) {
      const cloudType = e.target.value
      this.cCloudType = cloudType
      for (let c = 0; c < this.cloud.clouds.length; c++) {
        if (this.cloud.clouds[c].id === cloudType) {
          this.initParams(c)
          break
        }
      }
    },
    handleChangeEnable (e, domain) {
      if (e) {
        this.enableDomainNum++
      } else {
        this.enableDomainNum--
      }
      for (let i = 0; i < this.domains.length; i++) {
        const domain = this.domains[i]
        if (domain === domain.domain) {
          domain.enable = e
          this.domains.splice(i, 1, domain)
        }
      }
    },
    lastStep () {
      this.cStep--
    },
    nextStep () {
      const _this = this
      if (this.enableDomainNum === 0) {
        this.$message.error(this.$t('cloud.cert.auto.renew.enable.none'))
        return
      }
      this.cStep = 2
      const domainDns = []
      const mainDomains = []
      for (const domain of this.domains) {
           const arr = domain.domain.split('.')
           const newArr = [arr[arr.length - 2], arr[arr.length - 1]]
           const mainDomain = newArr.join('.')
           if (!mainDomains.includes(mainDomain)) {
             mainDomains.push(mainDomain)
             domainDns.push({
               domain: domain.domain,
               host: mainDomain,
               host_record: '_acme-challenge',
               record_type: 'CNAME',
               record_value: null,
               real_value: null,
               status: 'checking'
             })
           }
      }
      this.domainDns = domainDns
      for (let i = 0; i < domainDns.length; i++) {
        (function (dom, index) {
          setTimeout(function () {
            _this.checkDns(dom)
          }, 20 + index * 1500)
        })(domainDns[i], i)
      }
    },
    checkDns (domain) {
      checkDomainDNS({
        server_name: domain.domain,
        server_host_name: '*.' + domain.host,
        server_port: '443'
      }).then(res => {
        if (res.code === 0) {
          const data = res.data
          const d = data.domain
          for (let i = 0; i < this.domainDns.length; i++) {
            const cd = this.domainDns[i]
              if (cd.host === d) {
                cd.record_value = data.record_value + '.relaxcert.com'
                cd.status = (data.match ? 'yes' : 'no')
                this.domainDns.splice(i, 1, cd)
                break
              }
          }
        }
       }).catch(() => {
         const d = domain.host
        for (let i = 0; i < this.domainDns.length; i++) {
          const cd = this.domainDns[i]
          if (cd.host === d) {
            cd.status = 'fail'
            this.domainDns.splice(i, 1, cd)
            break
          }
        }
      })
    },
    recheck (domain) {
      const d = domain.host
      for (let i = 0; i < this.domainDns.length; i++) {
        const cd = this.domainDns[i]
        if (cd.host === d) {
          cd.status = 'checking'
          this.domainDns.splice(i, 1, cd)
          break
        }
      }
      this.checkDns(domain)
    },
    saveSetting () {
      this.saving = true
      saveCloudAPIAuthInfo({
        id: this.record ? this.record.id : null,
        lang: window.navigator.language,
        cloud_type: this.cCloudType,
        service_type: this.cServiceType,
        setting_field: sm4.encrypt(JSON.stringify(this.cSettings), SMKEY),
        domains: JSON.stringify(this.domains)
      }).then(res => {
         if (res.code === 0) {
            this.$parent.refreshTable()
            this.$layer.close(this.layerid)
         }
      })
    }
  }
}
</script>

<template>
  <div style="width: 100%;padding: 8px;">
    <div style="padding: 8px 24px">
      <a-steps :current="cStep" size="small">
        <a-step :title="$t('cloud.api.auth')" />
        <a-step :title="$t('cloud.domain.renew.option')" />
        <a-step :title="$t('cloud.dns.config')" />
      </a-steps>
    </div>

    <div v-if="cStep===0" style="margin-top: 12px;">
      <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }" @submit="handleSubmit">
        <a-form-item :label="$t('cloud.provider')">
          <a-radio-group
            v-decorator="['cloud_type',{ rules: [{ required: true, message: $t('cloud.provider.required') }] }]"
            @change="handleChangeCloudType">
            <a-row :gutter="8" >
              <a-col :md="8" v-for="c in cloud.clouds" :key="c.id">
                <a-radio :value="c.id">
                  <img class="cloud-logo" :src="c.cloud_logo" /><span style="margin-left: 2px;">{{ c.cloud_name }}</span>
                </a-radio>
              </a-col>
            </a-row>

          </a-radio-group>
        </a-form-item>
        <a-form-item :label="$t('cloud.service.product')">
          <a-radio-group v-decorator="['service_type',{ rules: [{ required: true, message: $t('cloud.service.product.required') }] }]">
            <a-radio-button :value="s.service_type" v-for="s in services" :key="s.service_type">
              {{ s.service_name }}
            </a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item :label="field.label" v-for="field in fields" :key="field.key">
          <a-input
            v-decorator="[field.key,{ rules: [{ required: field.required, message: field.message }] }]"
          />
        </a-form-item>
        <a-form-item :label="$t('cloud.config.name')">
          <a-input
            :placeholder="$t('cloud.config.name.tip')"
            v-decorator="['name',{ rules: [{ required: true, message: $t('cloud.config.name.tip') }] }]"
          />
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
          <a-button type="primary" html-type="submit" :loading="loading">
            {{ $t('cloud.action.next') }}
          </a-button>
        </a-form-item>
      </a-form>
    </div>
    <div v-if="cStep===1">
      <div style="padding-top: 12px;">
        <a-table :columns="columns" :data-source="domains" size="small" bordered>
          <a-switch
            @change="handleChangeEnable($event, record.domain)"
            slot-scope="text, record"
            :checked-children="$t('cloud.cert.auto.renew.on')"
            :un-checked-children="$t('cloud.cert.auto.renew.off')"
            :checked="record.enable"
            slot="enable"></a-switch>
        </a-table>
        <div style="position: relative;top:-48px;left:0;color:#999;">{{ $t('cloud.domain.enable.one') }}</div>
        <div style="text-align: center;">
          <a-button type="primary" @click="lastStep">  {{ $t('cloud.action.previous') }}</a-button>
          <a-button type="primary" class="margin-left-16" :diabled="enableDomainNum==0" @click="nextStep">  {{ $t('cloud.action.next') }}
          </a-button>
        </div>
      </div>
    </div>
    <div v-if="cStep===2">
      <div style="padding-top: 12px;">
        <a-table :columns="columns2" :data-source="domainDns" size="small" bordered>
          <div slot="status" slot-scope="text, record">
            <a-spin size="small" v-if="record.status=='checking'"/>
            <a-tag color="green" v-if="record.status=='yes'">{{ $t('cloud.dns.config.check.success') }}</a-tag>
            <a-tag color="red" v-if="record.status=='no'">{{ $t('cloud.dns.config.check.fail') }}</a-tag>
            <a-tag color="orange" v-if="record.status=='fail'">{{ $t('cloud.dns.config.check.error') }}</a-tag>
          </div>
          <div slot="action" slot-scope="text, record">
            <a @click="recheck(record)">{{ $t('cloud.dns.config.check') }}</a>
          </div>
        </a-table>
        <div style="position: relative;top:-44px;left:0;color:#999;" v-if="dnsErr">{{ $t('cloud.domain.dns.config.tip') }}</div>
        <div style="text-align: center;">
          <a-button type="primary" @click="lastStep">  {{ $t('cloud.action.previous') }}</a-button>
          <a-button type="primary" class="margin-left-16" @click="saveSetting" :loading="saving"> {{ $t('user.action.config.save') }}</a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.cloud-logo {
  width: 20px;
  height: 20px;
  position: relative;
  top: -1px;
}
</style>
